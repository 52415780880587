import React from 'react'

import SummaryAlpha from '../../components/template/SummaryAlpha.template'

const SummaryView = () => {
    return (
        <div className='summary-page'>
            <SummaryAlpha />
        </div>
    )
}

export default SummaryView
