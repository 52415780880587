import axios from 'axios'
import { buildUrlWithParameters } from '@sm360/helpers'

// TODO: Remove this after adding REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL into kubernetes
const PAYMENT_CALCULATOR_WIDGET_URL = 'https://widget-payment-calculator-360-int.sm360.ca'

const config = {
    headers: { authorization: `Basic c20zNjA6c20zNjA=` },
}

/**
 * Get Translations
 * @param { String } lang - Language
 * @param { String } domain - Domain
 * @returns {Promise<{}>}
 */
export const getTranslationsAPIUrl = async (lang, domain) => {
    const apiUrl = `/${lang}/website/api/config/tags`

    const location = domain || window?.web_domain
    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    try {
        const response = await axios.get(urlComplete, config)
        return response.data
    } catch (e) {
        return Promise.reject(e)
    }
}

/**
 * Get Quote Desking
 * @param { String } quoteId - Quote identifier
 * @param { String} token - Token identifier
 * @param { Object } dealerInfos - Dealer Infos
 * @returns {Promise<{}>}
 */
export const getQuoteDeskingAPIUrl = async (quoteId, token, dealerInfos) => {
    const apiUrl = `${process.env.REACT_APP_SHOWROOM_API_URL}/service/v1/quote/desking/${quoteId}`

    const { lang, location, orgId, orgUnitId } = dealerInfos

    let apiResponse = {}

    const defaultParams = {
        lang,
        location,
        orgId,
        orgUnitId,
        includeMeta: true,
        module: 2,
    }

    const urlWithParams = buildUrlWithParameters(apiUrl, {
        ...defaultParams,
    })

    const config = {
        method: 'get',
        url: urlWithParams,
        headers: {
            'x-jwt': token,
            'Content-Type': 'application/json',
            'accept-language': `${lang}-CA`,
        },
    }

    try {
        const response = await axios(config)
        if (response) apiResponse = await response.data
        return apiResponse
    } catch (error) {
        return apiResponse
    }
}

/**
 * Generator JWT
 * @returns {Promise<{}>}
 */
export const getJwt = async () => {
    const apiUrl = `${process.env.REACT_APP_SHOWROOM_API_URL}/service/v1/jwt?userName=public&password=public`

    let apiResponse = {}
    try {
        const response = await axios.get(apiUrl)
        if (response) apiResponse = await response.data
        return apiResponse
    } catch (error) {
        return apiResponse
    }
}

/**
 * Get widget payment calculator
 * @param { String } lang - Language
 * @param { String } quoteId - Quote ID
 * @param { Object } dealerInfo - Dealer info with orgId, orgUnitId, location etc...
 * @returns {Promise<{}>}
 */
export const getCalculatorApiUrl = async (lang, quoteId, dealerInfo) => {
    const { orgId, orgUnitId, location } = dealerInfo

    const baseUrl = `${PAYMENT_CALCULATOR_WIDGET_URL}/payment-calculator-360/render/modal?`

    const paramsUrl = {
        location,
        orgId,
        orgUnitId,
        lang,
        quoteId,
    }

    const apiUrl = buildUrlWithParameters(baseUrl, paramsUrl)
    let data = {}
    try {
        const response = await axios.get(apiUrl)
        data = await response.data
    } catch (error) {
        console.log(error.message, apiUrl)
    }
    return data
}
