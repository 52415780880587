import React from 'react'
import { hydrateRoot } from 'react-dom/client'

import { i18nConfig, I18nextProvider } from '../internationalization/src'

import { SummaryProvider } from '../configs/summary-view/state.manager'
import SummarySSR from './views/SummarySSR'

import '@sm360/summary-360/dist/views/summary/summary.view.css'

const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__

const { initialData } = preloadedState

hydrateRoot(
    document.getElementById('widget-summary-360'),
    <I18nextProvider i18n={i18nConfig}>
        <SummaryProvider initialData={initialData}>
            <SummarySSR />
        </SummaryProvider>
    </I18nextProvider>
)

if (module.hot) {
    module.hot.accept()
}
