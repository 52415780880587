import { getImageUrl } from '@sm360/helpers'

export const quoteDeskingTransform = (quoteInfo, t) => {
    const {
        accountId,
        cashDown,
        financePlan,
        financeOptions,
        id,
        kmPerYearPlan,
        leadId,
        lien,
        paymentFrequency,
        purchaseMethod,
        quoteRevision,
        saleType,
        taxPlan,
        term,
        tradeIn,
        transactionToken,
        vehicle: {
            exteriorColourPhotos,
            interiorTrimPhotos,
            make,
            model,
            odometer,
            stockNumber,
            vehicleId,
            vin,
            year,
            consumption,
            bodyStyle,
            bodyClassification,
            seating,
            doors,
            engine,
            transmission,
            driveTrain,
            newCarTrimId,
            modelTrim,
        },
        vehicleCondition,
    } = quoteInfo

    return {
        vehicle: {
            year,
            make,
            model,
            odometer,
            stockNumber,
            vehicleId,
            vin,
            consumption,
            bodyStyle,
            bodyClassification,
            seating,
            doors,
            engine,
            transmission,
            driveTrain,
            exteriorColourPhotos,
            interiorTrimPhotos,
            newCarTrimId,
            modelTrim,
        },
        gallery: [
            {
                label: t('vehicle.summary.exterior'),
                images: [
                    getImageUrl(exteriorColourPhotos?.front, 'w960h450c', 'newcar/'),
                    getImageUrl(exteriorColourPhotos?.side, 'w960h450c', 'newcar/'),
                    getImageUrl(exteriorColourPhotos?.rear, 'w960h450c', 'newcar/'),
                ],
            },
            {
                label: t('vehicle.summary.interior'),
                images: [
                    getImageUrl(interiorTrimPhotos?.front, 'w960h450c', 'newcar/'),
                    getImageUrl(interiorTrimPhotos?.rear, 'w960h450c', 'newcar/'),
                    getImageUrl(interiorTrimPhotos?.dashboard, 'w960h450c', 'newcar/'),
                ],
            },
        ],
        quoteInfos: {
            accountId,
            cashDown,
            financePlan,
            financeOptions,
            id,
            kmPerYearPlan,
            leadId,
            lien,
            paymentFrequency,
            purchaseMethod,
            quoteRevision,
            saleType,
            taxPlan,
            term,
            tradeIn,
            transactionToken,
            vehicleCondition,
        },
    }
}
