export const vehicleAccessoriesDummies = () => {
    // TODO: Remove this dummy data once we have Accessories and Options available from Desking builder
    const accessories = [
        {
            description: '17" Glint Black Alloy Wheels',
            photo: 'https://images.honda.ca/accessories/H/2024/215261_thumbnail.jpg?width=800',
            totalRetail: 2384.8,
        },
        {
            description: 'All Season Floor Mats',
            photo: 'https://images.honda.ca/accessories/H/2024/215269_thumbnail.jpg?width=800',
            totalRetail: 275.3,
        },
        {
            description: 'Ashtray, cup holder style',
            photo: 'https://images.honda.ca/accessories/H/2024/215275_thumbnail.jpg?width=800',
            totalRetail: 54,
        },
        {
            description: 'Auto-dimming interior mirror with HomeLink',
            photo: 'https://www.subaru.ca/Content/7907/media/General/Accessory/H501SFN000.jpg',
            totalRetail: 500.94,
        },
        {
            description: 'Auto-dimming exterior mirror with approach light',
            photo: 'https://www.subaru.ca/Content/7907/media/General/Accessory/J201SFN000.jpg',
            totalRetail: 275.3,
        },
        {
            description: 'All weather floor liners',
            photo: 'https://www.subaru.ca/Content/7907/media/General/Accessory/J501SFN010.jpg',
            totalRetail: 180.95,
        },
    ]
    return { accessories }
}
