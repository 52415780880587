import React from 'react'

import {
    DealerPromotion,
    PaymentOptions,
    SharingPanel,
    SummaryBar,
    TabSlider,
    VdpBreadcrumb,
    VehicleDescription,
    VehicleDisclaimer,
    VehicleHighlightCards,
    VehicleName,
    VehicleOptions,
    VehicleSpecs,
    VehicleTagline,
    VehicleAccessories,
} from '@sm360/components'
import { useTranslation } from '@sm360/hooks'

import { quoteDeskingTransform } from '../../transforms/quoteDesking.transform'
import { SummaryContext } from '../../configs/summary-view/state.manager'
import { vehicleAccessoriesDummies } from '../../dummies/vehicleAccessories.dummies'
import { resetParentsOverflow } from '@sm360/helpers/dist/global-functions'
import { setInnerHTML } from '@sm360/helpers'

const VdpAlpha = () => {
    const baseClass = 's360-p-summaryAlpha'

    const { t } = useTranslation()

    const {
        state: {
            dealerPromotion,
            quoteDesking,
            hubName,
            showroom360ConfigVdp: { summaryHighlightCardConfigurations },
            vehicleTrim: { vehicleTrim },
            calculatorWidget,
            tradeInWidget,
        },
    } = React.useContext(SummaryContext)

    const {
        gallery,
        vehicle,
        vehicle: {
            make,
            model,
            year,
            modelTrim,
            exteriorColourPhotos,
            interiorTrimPhotos,
            consumption,
            odometer,
            bodyStyle,
            bodyClassification,
            seating,
            doors,
            engine,
            transmission,
            driveTrain,
            newCarTrimId,
        },
        quoteInfos: { id: quoteId },
    } = quoteDeskingTransform(quoteDesking, t)

    // TODO: Remove this dummy data once we have Accessories and Options available from Desking builder
    const { accessories } = vehicleAccessoriesDummies()

    const vehicleInfo = {
        make,
        model,
        modelTrim,
        year,
        newCarTrimId,
        sideImg: exteriorColourPhotos?.side,
    }

    const { accessoriesAndOptions, fuel, features, disclaimer, paymentOptions } = vehicleTrim || {}

    const highlights = summaryHighlightCardConfigurations

    React.useEffect(() => {
        if (document) {
            resetParentsOverflow('.s360-p-summaryAlpha__aside')
        }
    })

    React.useEffect(() => {
        const dataStandalone = document.querySelectorAll('[data-standalone-trade]')
        if (dataStandalone.length > 0) {
            dataStandalone.forEach(async (element) => {
                if (element.innerHTML === '') {
                    await setInnerHTML(element, tradeInWidget)
                }
            })
        }
    }, [])

    return (
        <div className='sr360-p-inventoryVdp'>
            <SummaryBar vehicleInfo={vehicleInfo} />
            <div className={`${baseClass}`}>
                <div className={`${baseClass}__navigation`}>
                    <VdpBreadcrumb
                        make={make?.name}
                        model={model?.name}
                        year={year}
                        trim={modelTrim?.name}
                        hubCategory={hubName}
                        extraClasses={`${baseClass}__breadcrumb`}
                    />

                    <SharingPanel />
                </div>
                <main className={`${baseClass}__main`}>
                    <TabSlider tabs={gallery} />
                    <VehicleTagline tagline='Temp Tagline' />
                    <VehicleDescription description='Vehicle descripton here' />
                    {highlights?.length > 0 && (
                        <VehicleHighlightCards vehicleInventory={vehicle} highlights={highlights} quoteId={quoteId} />
                    )}
                    <div data-standalone-trade='tradeIn360'></div>
                    <VehicleSpecs
                        specs={{
                            consumption,
                            odometer,
                            frameCategory: bodyClassification?.frameCategory?.frameCategory,
                            bodyStyle: bodyStyle?.name,
                            doors,
                            passengers: seating,
                            engineType: engine?.name,
                            engineDescription: engine?.power,
                            fuel: fuel?.name,
                            transmission: transmission?.name,
                            gears: transmission?.speeds,
                            driveTrain: driveTrain?.name,
                            exteriorColor: exteriorColourPhotos?.description,
                            interiorColor: interiorTrimPhotos?.colourDescription,
                        }}
                    />
                    {accessoriesAndOptions && accessoriesAndOptions.length > 0 && (
                        <VehicleOptions options={accessoriesAndOptions} />
                    )}
                    {disclaimer && <VehicleDisclaimer disclaimer={disclaimer} />}
                    <DealerPromotion promotion={dealerPromotion?.promotions[0]} />
                    {/** TODO remove commented line once we can have accessories and options in INT from desking builder */}
                    {/* {accessoriesAndOptions && accessoriesAndOptions.length > 0 && <VehicleAccessories accessories={accessoriesAndOptions} />} */}
                    <VehicleAccessories accessories={accessories} />
                </main>

                <aside className={`${baseClass}__aside`}>
                    <section className={`${baseClass}__sticky`}>
                        <div className='vehicleInfo'>
                            <div className='vehicleInfo__container'>
                                <VehicleName
                                    year={year}
                                    makeName={make?.name}
                                    modelName={model?.name}
                                    trimName={modelTrim?.name}
                                    isFullName
                                />

                                <div className='vehicleTabs'>
                                    <PaymentOptions
                                        newVehicle={true}
                                        paymentOptions={paymentOptions}
                                        isDetailView
                                        calculatorWidget={calculatorWidget}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    )
}

export default VdpAlpha
