export const runtimeConfig =
    typeof window !== 'undefined'
        ? {
              // client
              REACT_APP_BUILD_ENV: window.variables_env.REACT_APP_BUILD_ENV,
              REACT_APP_IMAGESERVER_URL: window.variables_env.REACT_APP_IMAGESERVER_URL,
              REACT_APP_ASSETS_PATH: window.variables_env.REACT_APP_ASSETS_PATH,
          }
        : {
              // server
              REACT_APP_BUILD_ENV: process.env.REACT_APP_BUILD_ENV,
              REACT_APP_IMAGESERVER_URL: process.env.REACT_APP_IMAGESERVER_URL,
              REACT_APP_ASSETS_PATH: process.env.REACT_APP_ASSETS_PATH,
          }
