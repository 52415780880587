import React from 'react'

const initialState = {
    isFetching: false,
    dealerPromotion: {},
    quoteDesking: {},
    hubName: '',
    showroom360ConfigVdp: {},
    vehicleTrim: {},
    calculatorWidget: '',
    tradeInWidget: '',
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'fetching': {
            return {
                ...state,
                isFetching: true,
            }
        }
        case 'fetchSucceed': {
            return {
                ...state,
                isFetching: false,
            }
        }
        case 'updateState': {
            return {
                ...state,
                ...action.state,
            }
        }
        case 'init': {
            return {
                ...state,
                dealerPromotion: action.dealerPromotion,
                quoteDesking: action.quoteDesking,
                isFetching: true,
                hubName: action.hubName,
                showroom360ConfigVdp: action.showroom360ConfigVdp,
                vehicleTrim: action.vehicleTrim,
                calculatorWidget: action.calculatorWidget,
                tradeInWidget: action.tradeInWidget,
            }
        }
        default:
            return state
    }
}

const SummaryContext = React.createContext(initialState)

const SummaryProvider = ({ initialData, children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    if (!state.isFetching) {
        if (initialData) {
            dispatch({ type: 'init', ...initialData })
        }
    }

    return <SummaryContext.Provider value={{ state, dispatch }}>{children}</SummaryContext.Provider>
}

SummaryProvider.propTypes = {}

SummaryProvider.defaultProps = {}

export { SummaryContext, SummaryProvider }
